.footer {
  position: fixed;
  left: 0;
  bottom: 70px;
  width: 100%;
  background-color: #e5e4e2;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 12px 0 4px;
}

.copyrights {
  padding-top: 8px;
  font-size: smaller;
}

.disclaimer {
  margin-top: 0;
}

@media only screen and (max-height: 700px) {
  .footer {
    display: none;
  }
}

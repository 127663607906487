.App {
  text-align: center;
  min-height: 500px;
}

.app-body {
  margin: 0 12px;
}

h2, h3 {
  @media (max-height: 1000px) {
    margin-bottom: 0;
    font-size: smaller;
  }
  @media (max-height: 500px) {
    margin-top: 0;
  }
}

p {
  @media (max-height: 1000px) {
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: smaller;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.player {
  margin-top: 12px;
  width: 800px;

  @media (max-width: 800px) {
    width: 590px;
  }
  @media (max-width: 600px) {
    width: 380px;
  }
}

.checkboxes {
  margin-bottom: 8px;
}

.answer {
  line-height: 1.5rem;
  min-height: 100px;
  font-size: smaller;
}

.answer-item {
  display: inline-block;
  padding-left: 8px;
}

@media only screen and (max-width: 850px) {
  .player {
    width: 600px;
  }
}

@media only screen and (max-width: 600px) {
  .player {
    width: 500px;
  }

  .container {
    padding: 0 10px;
  }

  .trim {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .player {
    width: 360px;
  }

  .container {
    padding: 0 10px;
  }

  .answer {
    font-size: small;
  }
}
